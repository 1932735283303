<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark>
      <div
        class="d-flex align-center">
        <v-img
          alt="App logo"
          class="shrink mr-2"
          contain
          :src="require('./assets/logo.png')"
          transition="scale-transition"
          width="40" />
      </div>

      <v-toolbar-title>
        Coords Converter Tool
      </v-toolbar-title>

      <v-spacer />

      <v-btn
        v-if="!user"
        icon
        :to="{ name: 'Login' }">
        <v-icon>
          mdi-login
        </v-icon>
      </v-btn>
      <template
        v-else>
        {{ user.email }}
        <v-btn
          icon
          @click="logout">
          <v-icon>
            mdi-logout
          </v-icon>
        </v-btn>
      </template>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),

  computed: {
    user () {
      return this.$store.getters.user
    }
  },

  methods: {
    logout () {
      this.$store.dispatch('logout')
      if (this.$route.name !== 'Login') {
        this.$router.push({ name: 'Login' })
      }
    }
  }
}
</script>
