<template>
  <cc-index />
</template>

<script>
import Index from '../components/Index'

export default {
  name: 'Index',

  components: {
    'cc-index': Index
  }
}
</script>
