<template>
  <v-container fluid fill-height>
    <v-row class="text-center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card>
          <v-toolbar flat color="primary" dark>
            Autentificare
          </v-toolbar>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-text-field
                v-model="email"
                :rules="emailRules"
                label="Email"
                required />
              <v-text-field
                v-model="pass"
                type="password"
                :rules="passRules"
                label="Parola"
                required />
              <v-alert
                v-model="alert"
                border="left"
                dismissible
                text
                type="error">
                {{ mess }}
              </v-alert>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              elevation="0"
              text
              @click="submit">
              Login
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'cc-login',

  data: () => ({
    mess: null,
    alert: false,
    valid: true,
    email: null,
    pass: null,
    emailRules: [
      v => !!v || 'Introduceti adresa de email asociata contului',
      v => /.+@.+\..+/.test(v) || 'Adresa de email nu este valida'
    ],
    passRules: [
      v => !!v || 'Introduceti parola asociata contului',
      v => (v && !!v.trim().length) || 'You can\'t get away with just spaces, dude...'
    ]
  }),

  methods: {
    async submit () {
      this.$refs.form.validate()

      if (this.valid) {
        this.alert = false
        const result = await this.$store.dispatch('login', { email: this.email, password: this.pass })

        if (!result) {
          this.mess = 'Autentificare esuata...'
          this.alert = true
        } else {
          this.$router.push({ name: 'Index' })
        }
      }
    }
  }
}
</script>
