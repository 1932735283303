<template>
  <v-container fluid fill-height class="pa-0">
    <v-row>
      <v-col cols="12">
        <v-form ref="form">
          <v-row align="center">
            <v-col cols="2" class="text-center">
              <v-btn href="/input.xlsx" text color="primary" download>
                <v-icon left>mdi-file</v-icon>
                model
              </v-btn>
            </v-col>
            <v-col cols="8">
              <v-file-input
                :hint="fileHint"
                persistent-hint
                label="Sursa"
                prepend-icon="mdi-file-excel-outline"
                v-model="file"
                @change="_cbFileChanged">
              </v-file-input>
            </v-col>
            <v-col cols="1" class="text-center">
              <v-btn href="/output.xlsx" text primary icon :disabled="!lastFileDate" color="primary" :loading="loading" download title="Descarca fisierul rezultat">
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="1" class="text-center">
              <v-btn text primary icon color="primary" :loading="loading" title="Salveaza modificarile de pe harta" @click="_cbSaveChanges">
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
      <GmapMap
        ref="mapRef"
        :center="{ lat: 44.443190346449164, lng: 26.096513566795576 }"
        :style="{ width: '100%', height: 'calc(100% - 64px)' }"
        :zoom="17">
        <template v-for="(marker, index) in markers">
          <GmapMarker
            :position="{ lat: marker.lat, lng: marker.lng }"
            :key="index"
            v-if="marker.lat"
            @click="markerClicked(marker)"
            draggable
            @dragend="positionChanged($event, marker)" />
        </template>
        <gmap-info-window
        :position="infoPosition"
        :opened="infoOpen"
        @closeclick="infoClosed">
          <template v-if="selectedMarker">
            <template v-for="key in Object.keys(selectedMarker)">
              <div :key="key">
                <v-subheader class="pl-0">{{ key }}:</v-subheader>
                <p>{{ selectedMarker[key] }}</p>
                <v-divider></v-divider>
              </div>
            </template>
          </template>
        </gmap-info-window>
      </GmapMap>
      <v-card style="position: fixed; bottom: 0; left: 0" :style="{ display }">
        <v-card-title>Log</v-card-title>
        <v-card-text style="height: 30vh; overflow-y: scroll">
          <v-list dense>
            <v-list-item v-for="msg in messages" :key="msg">
              <v-list-item-title>{{ msg }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text>Inchide</v-btn>
        </v-card-actions>
      </v-card>
  </v-container>
</template>

<script>
import { gmapApi } from 'gmap-vue'

export default {
  name: 'cc-index',

  data: () => ({
    file: null,
    lastFileDate: null,
    loading: false,
    markers: [],
    infoOpen: false,
    selectedMarker: null,
    infoPosition: { lat: 0, lng: 0 },
    display: 'none'
  }),

  computed: {
    fileHint () {
      return this.lastFileDate ? `Ultima procesare: ${this.lastFileDate}` : 'nu exista nici un fisier procesat...'
    },
    google: gmapApi,
    messages () {
      return this.$store.getters.messages
    }
  },

  methods: {
    async _cbSaveChanges () {
      try {
        this.loading = true
        const response = await this.$store.dispatch('saveChanges', this.markers)
        const result = await response.json()
        this.lastFileDate = result.date
        this.markers = result.markers
        this.loading = false
      } catch (err) {
        alert('Whoops, eroare la salvare markere...')
        console.log(err)
        this.loading = false
      }
    },
    positionChanged ($event, marker) {
      this.$set(marker, 'lat', $event.latLng.lat())
      this.$set(marker, 'lng', $event.latLng.lng())
    },
    _cbCloseLog () {
      this.display = 'none'
    },
    async _cbFileChanged () {
      if (this.file) {
        try {
          this.loading = true
          this.display = 'block'
          const response = await this.$store.dispatch('convert', this.file)
          const result = await response.json()
          this.lastFileDate = result.date
          this.markers = result.markers
          this.fitMap()
          this.$store.commit('closePreviousConnection')
          this.display = 'none'
        } catch (err) {
          alert('Whoopsie! Eroare la procesre fisier...')
          console.log(err)
        } finally {
          this.loading = false
        }
      }
    },

    markerClicked (marker) {
      this.infoOpen = true
      this.selectedMarker = marker
      this.infoPosition = { lat: marker.lat, lng: marker.lng }
    },
    infoClosed () {
      this.infoOpen = false
      this.infoPosition = { lat: 0, lng: 0 }
      this.selectedMarker = null
    },

    fitMap () {
      if (this.markers.length) {
        this.$refs.mapRef.$mapPromise.then((map) => {
          const bounds = new this.google.maps.LatLngBounds()
          this.markers.forEach(marker => {
            if (marker.lat) {
              bounds.extend({ lat: +marker.lat, lng: +marker.lng })
            }
          })

          map.fitBounds(bounds)
        })
      }
    }
  },

  async created () {
    // check server for last file date
    const response = await this.$store.dispatch('getLastFileDate')
    const result = await response.json()
    this.lastFileDate = result.date
    this.markers = result.markers
    this.fitMap()
  }
}
</script>
