<template>
  <cc-login />
</template>

<script>
import Login from '../components/Login'

export default {
  name: 'cc-login-view',

  components: {
    'cc-login': Login
  }
}
</script>
