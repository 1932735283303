import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Login from '../views/Login.vue'

import store from '@/store/'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    meta: { auth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { auth: false }
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const auth = to.meta.auth || false
  if (auth) {
    const user = store.getters.user

    if (!user) {
      store.dispatch('reAuth').then(result => {
        store.commit('setAuth', result)
        proceed(result.user, auth)
      }).catch(err => {
        console.log(err)
        next({ name: 'Login' })
      })
    } else {
      proceed(user, auth)
    }
  } else {
    next()
  }

  function proceed (user, auth) {
    const isAllowed = auth || false
    if (isAllowed) next()
    else next({ name: 'Login' })
  }
})

export default router
