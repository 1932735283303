import Vue from 'vue'
import Vuex from 'vuex'

import SERVER_URL from '@/config'

import feathers from '@/feathers/'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    feathers,
    services: {
      auth: feathers.service('authentication'),
      users: feathers.service('users')
    },
    auth: {
      user: null,
      token: null
    },
    messages: []
  },
  getters: {
    user: state => state.auth.user,
    messages: state => state.messages
  },
  mutations: {
    setAuth (state, payload) {
      Vue.set(state.auth, 'user', payload.user)
      Vue.set(state.auth, 'token', payload.accessToken)
    },
    clearMessages (state) {
      state.messages = []
    },
    setStream (state, payload) {
      state.stream = payload
      state.messages = []

      payload.onmessage = event => {
        state.messages.unshift(event.data)
      }
      payload.onerror = event => {
        console.log('error', event)
      }
      payload.onopen = event => {
        console.log('open', event)
      }
    },
    closePreviousConnection (state) {
      if (state.stream) {
        state.stream.close()
        state.messages = []
      }
    }
  },
  actions: {
    reAuth ({ state }) {
      return state.feathers.reAuthenticate()
    },

    async login ({ state, commit }, payload) {
      if (payload) {
        return state.feathers.authenticate({
          strategy: 'local',
          ...payload
        }).then(response => {
          commit('setAuth', response)
          return response
          // this.dispatch('saveLogItem', { type: 'success', user: response.user.username, text: '(auth) Autentificare reusita!' })
        }).catch(err => {
          console.log('store: ', err)
          return false
          // this.dispatch('saveLogItem', { type: 'error', user: payload.email, text: '(auth) Autentificare esuata!' })
        })
      } else {
        return false
      }
    },
    logout ({ state, commit }) {
      // this.dispatch('saveLogItem', { type: 'success', user: state.auth.user.username, text: '(auth) Iesire din aplicatie.' })
      state.feathers.logout()
      commit('setAuth', { user: null, accessToken: null })
    },

    async convert ({ state, commit, dispatch }, payload) {
      commit('clearMessages')
      const file = await readFile(payload)
      const uid = Date.now() + state.auth.user._id
      // const buffer = await readFile(payload)
      commit('closePreviousConnection')
      dispatch('connectToMessages', { uid })

      await wait(1 * 1000)

      return fetch(`${SERVER_URL}/convert`, {
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: state.auth.token
        }),
        method: 'POST',
        body: JSON.stringify({
          file,
          uid
        })
      })
    },

    getLastFileDate () {
      return fetch(`${SERVER_URL}/existing-data`)
    },

    connectToMessages ({ commit }, payload) {
      const stream = new EventSource(`${SERVER_URL}/connection/?uid=${payload.uid}`)
      commit('setStream', stream)
    },

    saveChanges ({ state }, payload) {
      return fetch(`${SERVER_URL}/save`, {
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: state.auth.token
        }),
        method: 'POST',
        body: JSON.stringify(payload)
      })
    }
  }
})

async function readFile (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onerror = error => { console.log(error); reject(error) }
    reader.onload = async event => {
      resolve(event.target.result)
    }
    reader.readAsDataURL(file)
  })
}

function wait (howLong) {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(), howLong)
  })
}
